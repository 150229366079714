import withRoot from "./modules/withRoot";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import Block1 from "./modules/views/Block1";
import Block2 from "./modules/views/Block2";

function App({ history }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const MainBlock = isMobile ? Block2 : Block1;

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index mainBlockComponent={MainBlock} />
        </Route>
      </Switch>
    </Router>
  );
}

function Index({ mainBlockComponent: MainBlock }) {
  return (
    <React.Fragment>
      <MainBlock />
    </React.Fragment>
  );
}

export default withRoot(withRouter(App));
