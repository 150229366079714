import React from 'react';
import { withStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import logo1 from '../../photos/logo1.svg';
import logo from '../../photos/logo.svg';
import block from '../../photos/Block1.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsApp from '@mui/icons-material/WhatsApp';
import block2 from '../../photos/Block2.png';
import image1 from '../../photos/Block2_1.png';
import image2 from '../../photos/Block2_2.png';
import image3 from '../../photos/Block2_3.png';
import image4 from '../../photos/Block2_4.png';
import image5 from '../../photos/Block2_5.png';
import buttonImage from '../../photos/but.png';
import but1 from '../../photos/but1.png';
import block3 from '../../photos/Block3.jpg';
import block4 from '../../photos/Block4.png';
import image4_1 from '../../photos/Block4_1.svg';
import image4_2 from '../../photos/Block4_2.svg';
import image4_3 from '../../photos/Block4_3.svg';
import image4_4 from '../../photos/Block4_4.svg';
import image4_5 from '../../photos/Block4_5.svg';
import image4_6 from '../../photos/Block4_6.svg';
import Block5 from '../../photos/Block5.png';
import Block5_1 from '../../photos/Block5_1.png';
import Block5_1_1 from '../../photos/Block5_1_1.svg';
import Block5_1_2 from '../../photos/Block5_1_2.svg';
import Block6 from '../../photos/Block6.png';
import Block6_1 from '../../photos/Block6_1.svg';
import MailIcon from '../../photos/Mail.svg';
import PhoneIcon from '../../photos/Phone.svg';
import MapIcon from '../../photos/Map.svg';


const styles = (theme) => ({
  appBar: {
    backgroundColor: "black",
    backgroundSize: 'cover',
    height: "90px",
    width: "100%",
    backgroundPosition: 'center',
    position: 'relative',
    padding: '16px',
    position: 'fixed', // Зміни тут
    top: 0, // Це закріпить меню зверху екрану
    zIndex: 1000, // Додаємо високий z-index, щоб забезпечити йому найвищий пріоритет
    marginTop: theme.spacing(-1),
  },
  appBar1: {
    backgroundImage: `url(${block})`,
    backgroundSize: 'cover',
    height: "897px",
    width: "100%",
    backgroundPosition: 'center',
    position: 'relative',
  },

  block2Image: {
    width: '100%',
    height: '1287px', 
    marginTop: theme.spacing(4),
  },


  title5: {
    color: theme.palette.common.black,
    fontFamily: 'Noto Serif',
    fontSize: 52,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(8), 
    marginBottom: theme.spacing(5), 
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  center1: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(29),
  },
  
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rightLink: {
    fontSize: 44,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  image: {
    height: 211,
    width: 413,
    marginLeft: theme.spacing(4), 
    marginRight: theme.spacing(4), 
  },
  image_1: {
    height: 72,
    width: 90,
    marginLeft: 60, 
    marginRight: 24, 
  },

  
  button: {
    cursor: 'pointer',
    backgroundImage: `url(${buttonImage})`,
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    zIndex: 1,    
  position: 'relative',
    borderRadius: '50%',
    top: -20, 
    border: '1px solid white',
  },

  button1: {
    cursor: 'pointer',
    backgroundImage: `url(${but1})`,
    backgroundSize: 'cover',
    width: '382px',
    zIndex: 1,    
    height: '130px',
    marginTop: theme.spacing(15),
  },

  
  textBelowImage: {
    color: 'black',
    position: 'absolute',
    bottom: '-25px',
    textAlign: 'center',
  },

  textBelowImage2: {
    color: 'black',
    position: 'absolute',
    bottom: '-10px',
    textAlign: 'center',
  },
  
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  block2Container: {
    position: 'relative',
    background: `url(${block2}) center / cover no-repeat`,
    height: '1268px',
    width: '100%',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },

  imageContainer2: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(-4),
  },

  imageContainer3: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(-15),
  },

  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: theme.spacing(5), 
  },

  rowContainer2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: theme.spacing(9), 
  },

  block3Container: {
    background: `url(${block3}) center / cover no-repeat`, // Додаємо фон
    height: '1180px', // Встановлюємо висоту як 100% висоти екрану
    width: '100%', // Встановлюємо ширину як 100% ширини екрану
  },

  block4Container: {
    display: 'grid',
    gridTemplateRows: '100px 100px 100px auto', // Додайте 'auto' для нового рядка
    gridTemplateColumns: '100px 100px',
    gridGap: '70px', // Відстань між елементами (змініть на те, що вам потрібно)
    gridColumnGap: '600px', // відступ між стовпцями
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${block4}) center / cover no-repeat`, // Додаємо фон
    height: '898px', // Встановлюємо висоту як 100% висоти екрану
    width: '100%', // Встановлюємо ширину як 100% ширини екрану
  },
  imageContainerBlock4: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(-60),
  },

  imageBlock4: {
    height: '100%', // Змінено на відсотки, щоб зберегти пропорції
    width: '647px', // Змінено на відсотки, щоб зберегти пропорції
    cursor: 'pointer',
  },

  buttonContainer: {
    gridRow: '4', // Вказуємо, що кнопка розташовується в 4 рядку (новому рядку)
    gridColumn: '1 / span 2', // Займає обидва стовпці
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '-150px', // Змініть на потрібну відстань між кнопкою і блоками
  },

  block5Container: {
    background: `url(${Block5}) center / cover no-repeat`,
    height: '599px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  title5Block5: {
    color: theme.palette.common.white,
    fontFamily: 'Noto Serif',
    fontSize: 63,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '400px',
  }, 
  
  imageBlock5_1: {
    width: '1102px',
    height: '221px', // Змінено висоту
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${Block5_1}) center / cover no-repeat`, // Додаємо фон
  },

 imageBlock5_1_1: {
  marginLeft: "60px",
    width: '148px',
    height: '205px',
 },

  textBlock5_1: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontSize: 30,
    flex: 1,
    margin: '20px 20px', // Змінені відступи зліва та зправа
    whiteSpace: 'nowrap',
  },

  buttonBlock5_1_2: {
  backgroundImage: `url(${Block5_1_2})`,
  backgroundSize: 'cover',
  width: '261px',
  height: '48px',
  cursor: 'pointer',
  marginRight: "60px",
},

block6Container: {
  background: `url(${Block6}) center / cover no-repeat`,
  height: '545px',
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'repeat(6, 1fr)', // 6 рядків
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3), // відстань між елементами
  textAlign: 'center',
},

block6Container: {
  background: `url(${Block6}) center / cover no-repeat`,
  height: '545px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3), // відстань між елементами
  textAlign: 'center',
},

logoBlock6: {
  width: '252px',
  height: '187px',
},

textUnderLogo: {
  color: '#B99668',
  fontFamily: 'Roboto',
  fontSize: 32,
  fontWeight: 'bold',
  letterSpacing: '0.4em', // відстань між буквами
},

menuContainer: {
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  fontWeight: 'bold',
},

menuItem: {
  color: '#B99668',
  cursor: 'pointer',
  fontSize: 20,
  fontFamily: 'Roboto',
},

menuItem1: {
  fontSize: 20,
  color: '#B99668',
  fontFamily: 'Roboto',
  cursor: 'pointer',
  marginLeft: theme.spacing(2), // Відступ між пунктами меню
  textDecoration: 'none',
  '&:hover': {
    color: "#924721",
  },
},
socialMediaContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
},

socialMediaIcons: {  
  color: '#B99668',
},

imageBlock6_1: {
  width: '80%', // Замініть це на ширину, яку ви хочете надати зображенню
  height: 'auto', // Зберігає пропорції
  marginBottom: "40px",
},

contactContainer: {
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(-5), // Збільште або зменште це значення за потребою
},

contactItem: {
  display: 'flex',
  alignItems: 'center',
  color: '#B99668', // Помаранчевий колір посилань
  textDecoration: 'none', // Вимикаємо підкреслення тексту
  fontSize: 19,
},

contactIcon: {
  marginRight: '8px', // Додаємо відступ праворуч (8px) між іконкою та текстом
},

contactItemText: {
  textDecoration: 'none', // Вимикаємо підкреслення тексту
  color: '#B99668', // Зберігаємо колір тексту таким, яким він був
},

});
const Block1 = ({ classes }) => {
  const scrollToTarget = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const { top } = targetElement.getBoundingClientRect();
      const { innerHeight } = window;
      const offset = targetId === 'Block5' ? 0 : 85;

      window.scrollTo({
        top: window.scrollY + top - (innerHeight - targetElement.clientHeight) / 2 - offset,
        behavior: 'smooth',
      });
    }
  };
  const makeCall = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=380960968402'; // Замініть номером телефону за потреби
  };
  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <img src={logo} alt="Logo" className={classes.image_1} />
          </div>
          <div className={classes.center}>
          
          <div className={classes.menuContainer}>
          <div className={classes.menuItem1}>
          <div
            onClick={() => scrollToTarget('Block4')}
          >
            Послуги
          </div>
          </div>
          <div className={classes.menuItem1}>
          <div
            onClick={() => scrollToTarget('Block2')}
          >
            Переваги
          </div>
          </div>
          <div className={classes.menuItem1}>
          <div
            onClick={() => scrollToTarget('Block3')}
          >
            Про нас
          </div>
          </div>
          <div className={classes.menuItem1}>
          <div
            onClick={() => scrollToTarget('Block5')}
          >
            Контакти
          </div>
          </div>
          </div>
          </div>
          <div className={classes.right}>
            <a
              className={classes.rightLink}
              href="https://api.whatsapp.com/send?phone=380960968402"
            >
              <WhatsApp style={{ fontSize: 38, color: '#B99668' }} />
            </a>
            <a
              className={classes.rightLink}
              href="https://www.instagram.com/svoyi_tm?igsh=ZnBkeW83bXE3ZnYw"
            >
              <InstagramIcon fontSize="large" style={{ marginLeft: 14,  marginRight: 50, fontSize: 38, color: '#B99668' }} />
            </a>
          </div>
        </Toolbar>
      </AppBar>
<div className={classes.appBar1}>
  <div id="Block1" style={{ height: "897px", display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingTop: '20px' }}>
    <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'white', cursor: 'pointer', marginTop: '-400px', marginLeft: '250px', textDecoration: 'underline' }} onClick={makeCall}>+38-096-096-84-02</div>
  </div>
</div>
      <div className={classes.block2Container}>
        <div className={classes.contentContainer}>
          <div className={classes.title5}>З нами Ви зможете</div>
          
          <div className={classes.rowContainer}>
              <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
                
              <img src={image1} alt="Фінансова звітність" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Забезпечити правильне та своєчасне<br />подання фінансової та податкової звітності</div>
            </div>
            
          
              <div  id="Block2" className={classes.imageContainer}onClick={() => scrollToTarget('button1')}>
              <img src={image2} alt="Система оподаткуваня" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Підібрати оптимальну<br />систему оподаткування</div>
              
            </div>
              <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image3} alt="Кваліфікація співробітників" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Підвищення кваліфікації<br />співробітників вашого офісу</div>
              
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.rowContainer2}>
              <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
                <img src={image4} alt="Оптимізація витрат" className={classes.image} />
                <div className={classes.button}></div>
                <div className={classes.textBelowImage2}>Оптимізувати витрати</div>
              </div>
              <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
                <img src={image5} alt="Бізнес" className={classes.image} />
                <div className={classes.button}></div>
                <div className={classes.textBelowImage2}>Спокійно будувати свій бізнес</div>
              </div>
            </div>
          </div>

          <div className={classes.imageContainer}>
            <img src={but1} alt="Виклик" id="Button1" className={classes.button1} onClick={makeCall} />
          </div>
        </div>
      </div>
      <div className={classes.block3Container}>
      <div id="Block3" style={{ height: "900px" }}></div>
      </div>
      <div className={classes.block4Container}>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_1} alt="Бізнес під ключ" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_4} alt="Ведення бухгалтерії" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_2} alt="Оптимізація податків" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_5} alt="Онлайн Підтримка" id="Block4" className={classes.imageBlock4} />
        </div>
        
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_3} alt="Консультації" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_6} alt="Підвищення кваліфікації" className={classes.imageBlock4} />
        </div>
        <div className={classes.buttonContainer}>
  <img src={but1} alt="Виклик" id="button11" className={classes.button1} onClick={makeCall} />
</div>
      </div>

      <div className={classes.block5Container}>
        <div className={classes.title5Block5}>  
          Залишились питання?
        </div>
        <div className={classes.imageBlock5_1}>
          <img src={Block5_1_1} alt="Зв'язок з нами" className={classes.imageBlock5_1_1} />
          <div className={classes.textBlock5_1}>
            Тисніть на кнопку і ми обов’язково<br />Вам допоможемо
          </div>
          <div className={classes.buttonBlock5_1_2} id="Block5" onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=380960968402'}></div>

        </div>
      </div>

      <div className={classes.block6Container}>
      <div>
        <img src={logo1} alt="Лого" className={classes.logoBlock6} />
      </div> 
      <div className={classes.textUnderLogo}>
        Ми завжди на вашій стороні
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.menuItem} onClick={() => scrollToTarget('Block4')}>
          Послуги
        </div>
        <div className={classes.menuItem} onClick={() => scrollToTarget('Block2')}>
          Переваги
        </div>
        <div className={classes.menuItem} onClick={() => scrollToTarget('Block3')}>
          Про нас
        </div>
        <div className={classes.menuItem} onClick={() => scrollToTarget('Block5')}>
          Контакти
        </div>
      </div>
      <div className={classes.socialMediaContainer}>

    
  <a
              href="https://api.whatsapp.com/send?phone=380960968402  "
            >
              <WhatsApp style={{ fontSize: 45, color: '#B99668' }} />
            </a>
            <a
              href="https://www.instagram.com/svoyi_tm?igsh=ZnBkeW83bXE3ZnYw"
            >
              <InstagramIcon fontSize="large" style={{ margin: "center",   fontSize: 45, color: '#B99668' }} />
            </a>
      </div>
      <img src={Block6_1} alt="Separator" className={classes.imageBlock6_1} />
      <div className={classes.contactContainer}>
        <a href="mailto:agro-trans41735074@ukr.net" className={classes.contactItem}>
          <img src={MailIcon} alt="Mail" className={classes.contactIcon} />
          <div>agro-trans41735074@ukr.net</div>
        </a>
        <a href="tel:+380960968402" className={classes.contactItem}>
          <img src={PhoneIcon} alt="Phone" className={classes.contactIcon} />
          <div>+38-096-096-84-02</div>
        </a>
        <a href="https://www.google.com/maps/place/The+Hot+Logistics/@49.4490705,32.0516192,15z/data=!4m6!3m5!1s0x40d14d50ed42dfb9:0x40562cf61d37d1df!8m2!3d49.4490705!4d32.0516192!16s%2Fg%2F11smmp70hp?entry=ttu" className={classes.contactItem}>
          <img src={MapIcon} alt="Map" className={classes.contactIcon} />
          <div>м. Черкаси, Шевченко 145, офіс 321</div>
        </a>
      </div>
    </div>

    </div>
  );
};

export default withStyles(styles)(Block1);