import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from "./Home";
import reportWebVitals from './reportWebVitals';
import './modules/views/styles.scss';

ReactDOM.render(
  <div>
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </React.StrictMode>
  </div>,
  document.getElementById('root')
);

reportWebVitals();
