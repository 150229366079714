import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import logo1 from '../../photos/logo1.svg';
import logo from '../../photos/logo.svg';
import block from '../../photos/Block1_P.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsApp from '@mui/icons-material/WhatsApp';
import FaceBook from '@mui/icons-material/Facebook';
import block2 from '../../photos/Block2_P.png';
import image1 from '../../photos/Block2_1.png';
import image2 from '../../photos/Block2_2.png';
import image3 from '../../photos/Block2_3.png';
import image4 from '../../photos/Block2_4.png';
import image5 from '../../photos/Block2_5.png';
import buttonImage from '../../photos/but.png';
import but1 from '../../photos/but1.png';
import block3 from '../../photos/Block3_P.jpg';
import block4 from '../../photos/Block4_P.png';
import image4_1 from '../../photos/Block4_1.svg';
import image4_2 from '../../photos/Block4_2.svg';
import image4_3 from '../../photos/Block4_3.svg';
import image4_4 from '../../photos/Block4_4.svg';
import image4_5 from '../../photos/Block4_5.svg';
import image4_6 from '../../photos/Block4_6.svg';
import Block5 from '../../photos/Block5_P.png';
import Block5_1 from '../../photos/Block5_1_P.png';
import Block5_1_1 from '../../photos/Block5_1_1.svg';
import Block5_1_2 from '../../photos/Block5_1_2.svg';
import Block6 from '../../photos/Block6.png';
import Block6_1 from '../../photos/Block6_1.svg';
import Mail from '../../photos/Mail.svg';
import Phone from '../../photos/Phone.svg';
import Map from '../../photos/Map.svg';
import Menu from '@mui/icons-material/Menu';
import Button from '@material-ui/core/Button';
import Close from '@mui/icons-material/Close';


const styles = (theme) => ({
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },
  appBar: {
    backgroundColor: "black",
    backgroundSize: 'cover',
    height: "52px",
    width: "100%",
    backgroundPosition: 'center',
    position: 'relative',
    padding: '16px',
    position: 'fixed', // Зміни тут
    top: 0, // Це закріпить меню зверху екрану
    zIndex: 1000, // Додаємо високий z-index, щоб забезпечити йому найвищий пріоритет
    marginTop: theme.spacing(-1),
  },
  appBar1: {
    backgroundImage: `url(${block})`,
    backgroundSize: 'cover',
    height: "702px",
    width: "100%",
    backgroundPosition: 'center',
    position: 'relative',
  },

  block2Image: {
    width: '100%',
    height: '1287px', 
    marginTop: theme.spacing(4),
  },


  title5: {
    color: theme.palette.common.black,
    fontFamily: 'Noto Serif',
    fontSize: 52,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(8), 
    marginBottom: theme.spacing(5), 
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  center1: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    textShadow: '2px 2px 4px rgba(180, 150, 104, 1)' // Колір тіні такий же, як і колір тексту
  },
  
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(-3),
  },
  rightLink: {
    height: "20px",
    width: "20px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    top: '30%',
    transform: 'translate(0%, -50%)',
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  image: {
    height: "175px",
    width: "343px",
  },
  image_1: {
    height: "36px",
    width: "46px",
    marginLeft: -10, 
    marginRight: 0, 
    position: 'absolute',
    top: '30%',
    transform: 'translate(0%, -50%)',
  },

  
  button: {
    cursor: 'pointer',
    backgroundImage: `url(${buttonImage})`,
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    zIndex: 1,    
  position: 'relative',
    borderRadius: '50%',
    top: -20, 
    border: '1px solid white',
  },

  button1: {
    cursor: 'pointer',
    backgroundImage: `url(${but1})`,
    backgroundSize: 'cover',
    width: '382px',
    zIndex: 1,    
    height: '130px',
    marginTop: theme.spacing(4),
  },

  
  textBelowImage: {
    color: 'black',
    position: 'absolute',
    bottom: '-25px',
    textAlign: 'center',
    backgroundColor: 'rgba(185, 150, 104, 0.7)',
    padding: '5px 10px', // опціонально: додайте відступи, які вам потрібні
    borderRadius: '5px', // закруглені кути для фону тексту
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // напівпрозора рамка
    marginBottom: theme.spacing(-1),
  },
  
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(9),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'flex-end',
  },

  block2Container: {
    position: 'relative',
    background: `url(${block2}) center / cover no-repeat`,
    height: '1900px',
    width: '100%',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },

  imageContainer2: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(-4),
  },

  imageContainer3: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(-25),
  },

  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: theme.spacing(5), 
  },

  rowContainer2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: theme.spacing(9), 
  },

  block3Container: {
    background: `url(${block3}) center / cover no-repeat`, // Додаємо фон
    height: '1700px', // Встановлюємо висоту як 100% висоти екрану
    width: '100%', // Встановлюємо ширину як 100% ширини екрану
  },

  block4Container: {
    display: 'column',
    gridGap: '70px', // Відстань між елементами (змініть на те, що вам потрібно)
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${block4}) center / cover no-repeat`, // Додаємо фон
    height: '970px', // Встановлюємо висоту як 100% висоти екрану
    width: '100%', // Встановлюємо ширину як 100% ширини екрану
  },
  imageContainerBlock4: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  imageBlock4: {
    height: '82px', // Змінено на відсотки, щоб зберегти пропорції
    width: '344px', // Змінено на відсотки, щоб зберегти пропорції
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },

  buttonContainer: {
    gridRow: '4', // Вказуємо, що кнопка розташовується в 4 рядку (новому рядку)
    gridColumn: '1 / span 2', // Займає обидва стовпці
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '-150px', // Змініть на потрібну відстань між кнопкою і блоками
  },

  block5Container: {
    background: `url(${Block5}) center / cover no-repeat`,
    height: '920px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  title5Block5: {
    color: theme.palette.common.white,
    fontFamily: 'Noto Serif',
    fontSize: 45,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '400px',
    marginTop: theme.spacing(-35),
  }, 
  
  imageBlock5_1: {
    marginTop: theme.spacing(5),
    width: '316px',
    height: '521px', // Змінено висоту
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column', // Розташовуємо елементи в одному стовпці
    alignItems: 'center',
    justifyContent: 'center', // Це можливо також потрібно змінити залежно від ваших потреб
    background: `url(${Block5_1}) center / cover no-repeat`, // Додаємо фон
  },

 imageBlock5_1_1: {
    width: '176px',
    height: '244px',
 },

  textBlock5_1: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontSize: 25,
    flex: 1,
    margin: '20px 20px', // Змінені відступи зліва та зправа
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },

  buttonBlock5_1_2: {
  backgroundImage: `url(${Block5_1_2})`,
  backgroundSize: 'cover',
  width: '261px',
  height: '48px',
  cursor: 'pointer',
  marginBottom: theme.spacing(7),
},

block6Container: {
  background: `url(${Block6}) center / cover no-repeat`,
  height: '870px',
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'repeat(11, 1fr)', // 6 рядків
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(-1), // відстань між елементами
},
logoBlock6Container: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%', // Додаємо це для центрування логотипу по горизонталі всередині контейнера
},

logoBlock6: {
  width: '252px',
  height: '187px',
  margin: 'auto', // Залиште це для центрування логотипу відносно його реальних розмірів
  marginTop: theme.spacing(10),
},

textUnderLogo: {
  color: '#B99668',
  fontFamily: 'Roboto',
  fontSize: 22,
  fontWeight: 'bold',
  textAlign: 'center',
  letterSpacing: '0.1em',
  marginTop: theme.spacing(2),
},


socialMediaContainer: {
  display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  marginBottom: theme.spacing(-1), // Збільште або зменште це значення за потребою
  marginTop: theme.spacing(2),
  
},
socialMediaContainer1: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: "-50px",
  marginBottom: theme.spacing(0), // Збільште або зменште це значення за потребою
},

imageBlock6_1: {
  width: '100%',
  height: 'auto',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(-2),
},

contactContainer: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2), // Збільште або зменште це значення за потребою
},

contactItem: {
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1), // Збільште або зменште це значення за потребою
  color: "#B99668",
  fontWeight: 'bold',
  textDecoration: 'none',
  marginTop: theme.spacing(2),
  
},

contactIcon: {
  width: '30px', // Замініть це на ширину, яку ви хочете надати іконці
  height: 'auto', // Зберігає пропорції
  marginRight: theme.spacing(1), // Збільште або зменште це значення за потребою
  fontWeight: 'bold',
  marginTop: theme.spacing(5),
},

contactLinksContainer: {
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(5),
},

rectangle: {
    backgroundColor: 'black',
    width: '100%',
    height: '276px',
    position: 'fixed',
    top: 0,
    right: 0,
    display: 'none',
  },

  rectangleVisible: {
    display: 'block',
  },

  menuButton: {
    color: '#B99668',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  closeIcon: {
    color: '#B99668',
    marginLeft: 'auto',
    marginTop: theme.spacing(-3),
    marginRight: theme.spacing(-1),
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'black', // Фон чорний
    padding: theme.spacing(4),
    zIndex: 2,
    transform: 'translateX(100%)', // Починається за межами екрану
    transition: 'transform 0.3s ease-in-out',
  },
  menuVisible: {
    transform: 'translateX(0)', // З'являється на екрані
  },
  menuItem: {
    marginBottom: theme.spacing(3),
    color: '#B99668',
    fontSize: 20, // Зміна розміру тексту на 24px (або інший вибраний розмір)
    textShadow: '2px 2px 4px rgba(185, 150, 104, 0.8)' // Колір тіні такий же, як і колір тексту
  },
  
  yourNextDiv: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxWidth: '100%',
  },

  callIcon: {
    position: 'fixed',
    bottom: '250px',
    right: '20px',
    width: '75px',
    height: '75px',
    backgroundSize: 'cover',
    cursor: 'pointer',
    zIndex: '999',
    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.9)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    animation: '$blink 1s infinite',
  },
  
  
  
});
const Block2 = ({ classes }) => {
    const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };
  
    const scrollToTarget = (targetId) => {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const { top } = targetElement.getBoundingClientRect();
        const { innerHeight } = window;
  
        window.scrollTo({
          top: window.scrollY + top - (innerHeight - targetElement.clientHeight) / 2,
          behavior: 'smooth',
        });
      }
      
    setMenuVisible(false);
    };
  
    const makeCall = () => {
      window.location.href = 'tel:+380960968402';
    };
  return (
    <div class="yourNextDiv" >
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <img src={logo} alt="Logo" className={classes.image_1} />
          </div>
          <div className={classes.center}>
          <a
              className={classes.rightLink}
              href="https://api.whatsapp.com/send?phone=380960968402  "
            >
              <WhatsApp style={{ marginRight: 0, fontSize: 25, color: '#B99668' }} />
            </a>
            <a
              className={classes.rightLink}
              href="https://www.facebook.com/profile.php?id=61555103295938"
            >
              <FaceBook style={{ marginRight: 0, fontSize: 25, color: '#B99668' }} />
            </a>
            <a
              className={classes.rightLink}
              href="https://www.instagram.com/svoyi_tm?igsh=ZnBkeW83bXE3ZnYw"
            >
              <InstagramIcon fontSize="large" style={{ marginLeft: 0,  marginRight: 0, fontSize: 25, color: '#B99668' }} />
            </a>
          </div>
          <div className={classes.right}>
          <Menu className={classes.menuButton} onClick={toggleMenu} />
          <div className={`${classes.menu} ${menuVisible ? classes.menuVisible : ''}`}>
            <Close className={classes.closeIcon} onClick={closeMenu} />
            <Button className={classes.menuItem} onClick={() => scrollToTarget('Block4')}>
              Послуги
            </Button>
            <Button className={classes.menuItem} onClick={() => scrollToTarget('Block2')}>
              Переваги
            </Button>
            <Button className={classes.menuItem} onClick={() => scrollToTarget('Block3')}>
              Про нас
            </Button>
            <Button className={classes.menuItem} onClick={() => scrollToTarget('Block5')}>
              Контакти
            </Button>
          </div>
          </div>
        </Toolbar>
      </AppBar>
      <IconButton className={classes.callIcon} onClick={makeCall}>
  <img src={Phone} alt="Phone" style={{ width: '100%', height: '100%' }} />
</IconButton>
<div className={classes.appBar1}>
  <div id="Block1" style={{ height: "683px", width: "100%", display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingTop: '20px' }}>
    <div style={{ fontSize: '17px', fontWeight: 'bold', color: 'white', cursor: 'pointer', marginTop: '-350px', marginLeft: '0px', textDecoration: 'underline' }} onClick={makeCall}>+38-096-096-84-02</div>
  </div>
</div>


      <div className={classes.content}>
        <div className={classes.block2Container}>
          <div className={classes.contentContainer}>
            <div className={classes.title5}>З нами Ви зможете</div>
            <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image1} alt="Фінансова звітність" className={classes.image} id="Block2" />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Забезпечити правильне та своєчасне<br />подання фінансової та податкової звітності</div>
            </div>
            <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image2} alt="Система оподаткуваня" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Підібрати оптимальну<br />систему оподаткування</div>
            </div>
            <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image3} alt="Кваліфікація співробітників" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Підвищення кваліфікації<br />співробітників вашого офісу</div>
            </div>
            <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image4} alt="Оптимізація витрат" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Оптимізувати витрати</div>
            </div>
            <div className={classes.imageContainer} onClick={() => scrollToTarget('button1')}>
              <img src={image5} alt="Бізнес" className={classes.image} />
              <div className={classes.button}></div>
              <div className={classes.textBelowImage}>Спокійно будувати свій бізнес</div>
            </div>
            <div className={classes.imageContainer}>
              <img src={but1} alt="Виклик" id="button1" className={classes.button1} onClick={makeCall} />
            </div>
          </div>

        </div>
      </div>
      <div className={classes.block3Container}>
      <div id="Block3" style={{ height: "700px" }}></div>
      </div>
      <div className={classes.block4Container}>
      <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
  <img src={image4_1} alt="Бізнес під ключ" className={classes.imageBlock4}  style={{ marginTop: '150px' }}/>
</div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_4} alt="Ведення бухгалтерії" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_2} alt="Оптимізація податків" id="Block4" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_5} alt="Онлайн Підтримка" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_3} alt="Консультації" className={classes.imageBlock4} />
        </div>
        <div className={classes.imageContainerBlock4} onClick={() => scrollToTarget('button11')}>
          <img src={image4_6} alt="Підвищення кваліфікації" className={classes.imageBlock4} />
        </div>
        <div className={classes.buttonContainer}>
          <img src={but1} alt="Виклик" id="button11" className={classes.button1} onClick={makeCall} style={{ marginTop: '100px' }}/>
        </div>
      </div>

      <div className={classes.block5Container}>
  <div id="Block5"></div>
  <div className={classes.title5Block5}>
    Залишились питання?
  </div>
  <div className={classes.imageBlock5_1}>
    <img src={Block5_1_1} alt="Зв'язок з нами" className={classes.imageBlock5_1_1} />
    <div className={classes.textBlock5_1}>
      Тисніть на кнопку і ми<br />обов’язково<br />Вам допоможемо
    </div>
    <div className={classes.buttonBlock5_1_2} onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=380960968402'}></div>
  </div>
</div>


      <div className={classes.block6Container}>
      <div className={classes.logoBlock6Container}>
        <img src={logo1} alt="Logo" className={classes.logoBlock6} />
      </div>
      <div className={classes.textUnderLogo}>
        Ми завжди на вашій стороні
      </div>
      <div className={classes.center1} style={{ flexDirection: 'column', fontWeight: 'bold'}}>
  <div
    style={{ color: '#B99668', cursor: 'pointer', fontSize: 24, fontFamily: 'Roboto', marginBottom: '15px' }}
    onClick={() => scrollToTarget('Block4')}
  >
    Послуги
  </div>
  <div
    style={{ color: '#B99668', cursor: 'pointer', fontSize: 24, fontFamily: 'Roboto', marginBottom: '10px' }}
    onClick={() => scrollToTarget('Block2')}
  >
    Переваги
  </div>
  <div
    style={{ color: '#B99668', cursor: 'pointer', fontSize: 24, fontFamily: 'Roboto', marginBottom: '10px' }}
    onClick={() => scrollToTarget('Block3')}
  >
    Про нас
  </div>
  <div
    style={{ color: '#B99668', cursor: 'pointer', fontSize: 24, fontFamily: 'Roboto', marginBottom: '10px' }}
    onClick={() => scrollToTarget('Block5')}
  >
    Контакти
  </div>
</div>
<div className={classes.socialMediaContainer} style={{ marginBottom: '-5px', marginTop: '30px'  }}>
  <a href="https://www.instagram.com/svoyi_tm?igsh=ZnBkeW83bXE3ZnYw">
    <InstagramIcon fontSize="large" style={{ marginRight: 10, fontSize: 32, color: '#B99668' }} />
  </a>
  <a href="https://www.facebook.com/profile.php?id=61555103295938">
    <FaceBook style={{ marginRight: 10, marginLeft: 10, fontSize: 32, color: '#B99668' }} />
  </a>
  <a href="https://api.whatsapp.com/send?phone=380960968402">
    <WhatsApp style={{ marginLeft: 10, fontSize: 32, color: '#B99668' }} />
  </a>
</div>
<div className={classes.socialMediaContainer1} style={{ marginTop: '-10px' }}>
  <a>
    <img src={Block6_1} alt="Block6_1" className={classes.imageBlock6_1} />
  </a>
</div>
<div className={classes.contactLinksContainer} style={{flexDirection: 'column', marginTop: '40px' }}>
<a href="https://mail.ukr.net/desktop#sendmsg" className={classes.contactItem}>
  <img src={Mail} alt="Mail" style={{ width: 38, height: 38, marginLeft: 14, color: '#B99668' }} />
  <div>agro-trans41735074@ukr.net</div>
</a>
          <a href="tel:+380960968402" className={classes.contactItem}>
  <img src={Phone} alt="Phone" style={{ width: 38, height: 38, marginLeft: 14, color: '#B99668' }} />
  <div>+38-096-096-84-02</div>
</a>
          <a href="https://www.google.com/maps/place/The+Hot+Logistics/@49.4490705,32.0516192,15z/data=!4m6!3m5!1s0x40d14d50ed42dfb9:0x40562cf61d37d1df!8m2!3d49.4490705!4d32.0516192!16s%2Fg%2F11smmp70hp?entry=ttu" className={classes.contactItem}>
  <img src={Map} alt="Map" style={{ width: 38, height: 38, marginLeft: 14, color: '#B99668' }} />
  <div>м. Черкаси, Шевченко 145, офіс 321</div>
</a>
</div>
    </div>


    </div>
  );
};

export default withStyles(styles)(Block2);
